<template>
    <div class="exception-body error">
        <div class="p-6">
            <Card>
                <template v-slot:title>
                    <div class="flex">
                        <img src="layout/images/pages/icon-error.svg" alt="roma" />
                        <h1 class="ml-4">Ocorreu um erro</h1>
                    </div>
                </template>
                <template v-slot:subtitle>
                    <h5>Tente novamente, caso o erro persista entre em contato com o suporte.</h5>
                </template>
                <template v-slot:content>
                    <p>
                        {{ $store.getters.requestError }}
                    </p>
                    <Button label="Voltar para o início" icon="pi pi-arrow-left" @click="goDashboard" />
                </template>
            </Card>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goDashboard() {
            this.$router.push({ path: '/' });
        },
    },
};
</script>

<style scoped>
</style>
